<template>

	<div class="page" style="max-width: 600px; margin: 0 auto;">
		<div style="background-color: #99d6ff;position: relative;">
			<img src="../../../public/img/wxqcbg.jpg" style="width: 100%; vertical-align: middle;" />
			
			
			<div style="width:60%; height:40px;position: absolute; background-color: rgba(0, 0, 0, 0.4); top: 48%; left: 20%;">
				<input  class="inputtxt" v-model="stu_name" placeholder="请在此输入姓名"/>
				
			</div>
			
			<div
				style="position: absolute; width: 50%; height: 14vw; z-index: 1; top: 130vw; left: 25%;  " @click="goNext()">
				
			</div>


		</div>
		
		<div v-if="usrList.length>1" style="position: fixed; width:100%;height:100%;top:0;left:0;z-index:10;background:rgba(0,0,0,0.7)">
			<div style="height: 30%;"></div>
			<div style="width:60%; padding:20px;margin:0 auto;background:#fff;border-radius: 4px;">
				<div style="text-align: center;">请选择所在班级</div>
				<div @click="uid=cls.id" :class="['selectitem',uid==cls.id?'selected':'']" v-for="(cls,i) in usrList" :key="i">{{cls.grade_name}}-{{cls.class_name}}</div>
				
				
				<div class="selectitem selected" @click="goIvst()">开始答题</div>
			</div>
			
			
		</div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				urlScheme: "",
				stu_name:"",
				usrList:[],
				uid:0
				///pages/wenjuan/wenjuan/wenjuan?f=sm&plan_id=xxx
				//wxc154c9fca4a1c193南卫去实习
			}
		},
		mounted() {
			//plan_id要根据创建的来
			// this.$http.post("/api/get_url_scheme",{plan_id:145,pmid:7}).then(res=>{
			// 	this.urlScheme = res.data.urlScheme
			// })
	
		},
		methods: {
			goNext() {
				this.$http.post("/api/stu_name_touid",{stu_name:this.stu_name,pmid:7}).then(res=>{
					console.log(res.data)
					if(res.data&&res.data.length>0){
						if(res.data.length==1){
							this.$http.post("/api/h5_ivst_plan",{plan_id:145,pmid:7}).then(res2=>{
								if(res2.data.temp&&res2.data.temp.length>0){
									this.$router.push("/ivst?plan=7.145.s.s.s&id="+res2.data.temp[0].id+"&pmid=7&uid="+res.data[0].id)
								}
								//this.$router.push("/ivst?plan=7.145.s.s.s&id="+res.data+"&pmid=7&uid="+this.uid)
								
							})
							
							//
						}else{
							this.usrList = res.data
						}
					}else{
								alert("非毕业状态，无法参与问卷")
							}
					
				})
			},
			goIvst(){
				if(this.uid){
					//获取计划下的问卷
					this.$http.post("/api/h5_ivst_plan",{plan_id:145,pmid:7}).then(res2=>{
						if(res2.data.temp&&res2.data.temp.length>0){
							this.$router.push("/ivst?plan=7.145.s.s.s&id="+res2.data.temp[0].id+"&pmid=7&uid="+this.uid)
						}
						
						
					})
					
				}else{
					alert("请选择所在班级")
				}
			}

		}
	}
</script>

<style scoped>
	.btna { display: inline-block; padding: 0; margin: 0; width: 100%; height: 100%; outline: none; text-decoration: none;}
	.inputtxt{ width: 100%; height: 100%; margin: 0; padding: 0; text-align: center; border: 0; background-color: azure; font-size:24px}
	.selectitem{ border:1px solid #eee; border-radius: 4px; line-height: 40px; text-align: center; margin-top: 20px;}
	.selected{
		border:1px solid dodgerblue;
		background-color: dodgerblue;
		color: #fff;
	}
</style>
